module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.15.7_@emotion+react@11.14.0_@mdx-js+react@2.3.0_@theme-ui+css@0.15.7_4h2d7upfyv2xqdokxwdi5pdzrm/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.14.1_i18next@22.5.1_react-i18next@12.3.1_react@18.3.1/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en"],"defaultLanguage":"de","fallbackLanguage":"de","siteUrl":"https://jhaus.dev","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/:lang?","languages":["de","en"]},{"matchPath":"/:lang?/blog/:uid","languages":["de","en"]},{"matchPath":"/:lang?/impressum","languages":["de","en"]},{"matchPath":"/:lang?/datenschutz","languages":["de","en"]}],"redirect":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_graphql@16.10.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cara - @lekoarts/gatsby-theme-cara","short_name":"Cara","showThemeAuthor":false,"description":"Playful and Colorful One-Page portfolio featuring Parallax effects and animations","start_url":"/","background_color":"#141821","display":"standalone","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.7.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
