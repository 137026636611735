import React from 'react';
import { I18nextProvider } from 'gatsby-plugin-react-i18next';

interface WrapperProps {
  children: React.ReactNode;
}

const I18nWrapper: React.FC<WrapperProps> = ({ children }) => {
  return (
    <>{children}</>
  );
};

export default I18nWrapper;
